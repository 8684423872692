import { Component, OnDestroy, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { getErrorRegisterCard, isLoadingRegisterCard } from '../../../state/client/client.reducers'
import { Store } from '@ngrx/store'
import { AppState } from 'src/state/app.state'
import { AddCardHelper } from './register-card.helper'
import { Item } from '../select-box/select-box.component'
import { Subject, filter, switchMap, takeUntil } from 'rxjs'
import { CEPService } from './register-card.service'
import { ModalService } from '../modal/modal.service'
import { EncryptCardDataDto } from 'src/gateway/client/client.gateway'
import { ClientActions } from 'src/state/client/client.actions'
import { Location } from '@angular/common'
import { Actions, ofType } from '@ngrx/effects'

@Component({
	selector: 'app-register-card',
	templateUrl: './register-card.component.html',
	styleUrls: ['./register-card.component.scss'],
})
export class RegisterCardComponent implements OnInit, OnDestroy {
	creditCardForm!: FormGroup
	isLoadingRegisterCard$ = this.store.select(isLoadingRegisterCard)
	states = AddCardHelper.STATES_NAME_ID.map(state => ({ name: state.name, value: state.id })) as Item[]
	selectedState = { name: AddCardHelper.DEFAULT_STATE, value: '' }
	destroy$ = new Subject<void>()

	error$ = this.store.select(getErrorRegisterCard)
	modal = {
		cvv: 'modal-cvv-id',
		failure: 'modal-register-card-failure-id',
		success: 'modal-register-card-success-id',
	}

	constructor(
		private fb: FormBuilder,
		private store: Store<AppState>,
		private cepService: CEPService,
		public modalService: ModalService,
		public location: Location,
		private actions$: Actions
	) {}
	ngOnInit(): void {
		this.creditCardForm = this.createCreditCardForm()
		this.updateAddressFromCep()
		this.actions$
			.pipe(ofType(ClientActions.registerCardFailure), takeUntil(this.destroy$))
			.subscribe(() => {
				this.modalService.open(this.modal.failure)
			})
		this.actions$
			.pipe(ofType(ClientActions.registerCardSuccess), takeUntil(this.destroy$))
			.subscribe(() => {
				this.modalService.open(this.modal.success)
			})
	}

	ngOnDestroy(): void {
		this.destroy$.next()
		this.destroy$.complete()
	}

	private createCreditCardForm(): FormGroup {
		return this.fb.group({
			number: [null, Validators.required],
			name: ['', Validators.required],
			validity: [null, Validators.required],
			cvv: ['', Validators.required],
			cpf: ['', Validators.required],
			phone: ['', Validators.required],
			billingAddress: this.fb.group({
				zipCode: ['', Validators.required],
				state: ['', Validators.required],
				city: ['', Validators.required],
				address: ['', Validators.required],
			}),
			mainCard: [false],
		})
	}

	handleSubmit() {
		if (this.creditCardForm.invalid) return
		const { billingAddress, mainCard, phone } = this.creditCardForm.value
		this.store.dispatch(ClientActions.updateClientBackend({ phone }))
		this.store.dispatch(
			ClientActions.registerCard({ encryptData: this.encryptData, billingAddress, mainCard, cpf: this.creditCardForm.value.cpf })
		)
	}

	private get encryptData(): EncryptCardDataDto {
		const [expMonth, expYear] = this.creditCardForm.value.validity.split('/')
		const encryptCard = {
			number: this.creditCardForm.value.number,
			holder: this.removeSpecialCaractere(this.creditCardForm.value.name),
			cvv: this.creditCardForm.value.cvv,
			expMonth: +expMonth,
			expYear: +`20${expYear}`,
		}
		return encryptCard
	}

	private removeSpecialCaractere(str: string) {
		return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
	}

	setState(uf: Item) {
		this.selectedState = { name: uf.name, value: uf.value }
		this.creditCardForm.patchValue({
			billingAddress: {
				state: uf.value,
			},
		})
	}

	private updateAddressFromCep() {
		return this.creditCardForm
			.get('billingAddress.zipCode')
			?.valueChanges.pipe(
				filter(zipCode => zipCode.toString().length >= 8),
				switchMap(zipCode => this.cepService.getCEP(zipCode)),
				takeUntil(this.destroy$)
			)
			.subscribe(address => {
				if (address?.erro) return

				this.selectedState = { name: AddCardHelper.findStateByUf(address.uf), value: address.uf }
				this.creditCardForm.patchValue({
					billingAddress: {
						state: address.uf,
						city: address.localidade,
						address: address.logradouro,
					},
				})
			})
	}

	openModal(id: string) {
		this.modalService.open(id)
	}
}
