import { createAction, props } from '@ngrx/store'
import { Category } from 'src/entity/category.vo'
import { Client } from 'src/entity/client.entity'
import { BillingAddress, EncryptCardDataDto } from 'src/gateway/client/client.gateway'

// Client
const loginClient = createAction('[Client API] Load Client', props<{ id: string }>())
const loginClientHomologation = createAction('[Client API] Load Client Homologation', props<{ id: string }>())
const loginClientSSO = createAction('[Client API] Load Client SSO', props<{ token: string }>())
const loginClientSuccess = createAction('[Client API] Load Client Success', props<{ client: Client }>())
const loginClientFailure = createAction('[Client API] Load Client Fail', props<{ error: string }>())
const logoutClient = createAction('[Client] Logout Client')

const removeToken = createAction('[Client State] Remove Token')
const setRedirectUrl = createAction('[Client State] Set Redirect Url', props<{ url: string | null }>())
const registerCard = createAction(
	'[Client API] Add New Card',
	props<{ encryptData: EncryptCardDataDto; billingAddress: BillingAddress; mainCard: boolean, cpf: string }>()
)

const registerCardSuccess = createAction('[Client API] Register Card Success')
const registerCardFailure = createAction('[Client API] Register Card Failure', props<{ error: string }>())
const updateClient = createAction('[Client State] Update Client')

const removeCard = createAction('[Client State] Remove Card', props<{ lastFourDigits: string }>())
const removeCardSuccess = createAction('[Client State] Remove Card Success')
const removeCardFailure = createAction('[Client State] Remove Card Failure', props<{ error: string }>())

const updateMainCard = createAction('[Client State] Update Main Card', props<{ lastFourDigits: string }>())
const updateMainCardSuccess = createAction('[Client API] Update Main Card Success')
const updateMainCardError = createAction('[Client API] Update Main Card Failure', props<{ error: string }>())

const updateClientBackend = createAction('[Client State] Update Client Backend', props<{ phone: string }>())
const updateClientBackendSuccess = createAction('[Client State] Update Client Backend Success')
const updateClientBackendFailure = createAction(
	'[Client State] Update Client Backend Failure',
	props<{ error: string }>()
)

const selectCategory = createAction(
	'[Client] Change Category',
	props<{ gatewayId: string; lastFourDigits: string }>()
)

export const ClientActions = {
	loginClient,
	loginClientSSO,
	loginClientSuccess,
	loginClientFailure,
	removeToken,
	setRedirectUrl,
	registerCard,
	registerCardSuccess,
	registerCardFailure,
	updateClient,
	removeCard,
	removeCardSuccess,
	removeCardFailure,
	updateMainCard,
	updateMainCardSuccess,
	updateMainCardError,
	logoutClient,
	selectCategory,
	updateClientBackend,
	updateClientBackendSuccess,
	updateClientBackendFailure,
	loginClientHomologation,
}
