import { Injectable } from '@angular/core'
import { CreatePaymentDto, PaymentGateway } from './payment.gateway'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'

@Injectable()
export class HttpPaymentsGateway implements PaymentGateway {
	baseURL = `${environment.channelsAPI.baseUrl}/api/v1/payments`
	constructor(private http: HttpClient) {}

	createPayment(paymentDto: CreatePaymentDto): Observable<unknown> {
		return this.http.post(this.baseURL, paymentDto)
		// return throwError(() => ({ error: { message: 'hello' } }))
		// // return of({
		// // 	code: '12323',
		// // 	paidAt: '2023-03-03T10:00:00',
		// // 	orderTotalValue: 300_00,
		// // 	points: '23232',
		// // }).pipe(delay(1000))
	}

	getOrdersPaymentReady(clientId: string): Observable<unknown> {
		return this.http.get(`api/v1/orders/payment/ready/${clientId}`)
	}
}

export interface FilterHistoryQueryDto {
	clientId?: string
	partnerId?: string
	cpf?: string
	intent?: string
	startDate?: Date
	endDate?: Date
	skip?: number
	limit?: number
}
