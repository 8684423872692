export class Category {
	categoryId: string
	tokenCard?: string
	gatewayId?: string
	lastFourDigits?: string
	isRealCard: boolean
	mainCard?: boolean
	brand?: string
	constructor(props: CategoryProps) {
		this.categoryId = props.categoryId
		this.tokenCard = props?.tokenCard
		this.gatewayId = props?.gatewayId
		this.lastFourDigits = props?.lastFourDigits
		this.isRealCard = Boolean(props?.gatewayId)
		this.mainCard = Boolean(props?.mainCard)
		this.brand = props?.brand
	}
}

export interface CategoryProps {
	categoryId: string
	digits: number
	tokenCard?: string
	gatewayId?: string
	lastFourDigits?: string
	mainCard?: boolean
	brand?: string
}
